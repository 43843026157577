<template>
  <Card
    :title="title"
    :text="text"
    :image="image"
    :image-size="imageSize"
    :layout="layout"
    :buttons="buttons"
    :image-alt-text="imageAltText"
    :headline-level="headlinelevel"
    :srcset="srcset"
    :image-dims="imageDims"
    :preserve="preserve"
  />
</template>

<script>
  import { AlchemyElement, getElementsValue } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    props: {
      imageSize: {
        type: Number,
        default: undefined,
      },
      preserve: {
        type: Boolean,
        default: false,
      },
      layout: {
        type: String,
        default: "grid",
      },
    },
    computed: {
      imageIngredient() {
        return this.element.ingredients.find((i) => i.role === "image")
      },
      text() {
        return this.getValue("text")
      },
      headlinelevel() {
        return (
          this.getValue("headlinelevel") || this.getIngredient("title")?.level
        )
      },
      title() {
        return this.getValue("title")
      },
      image() {
        return this.imageIngredient?.value
      },
      imageDims() {
        return this.imageIngredient?.imageDimensions
      },
      srcset() {
        return this.imageIngredient?.srcset
      },
      imageAltText() {
        return this.imageIngredient?.altText
      },
      buttons() {
        return this.element.nestedElements.map((el) => {
          const link = el.ingredients.find((i) => i.role === "link")
          return {
            id: el.id,
            color: getElementsValue(el, "color"),
            layout: getElementsValue(el, "layout"),
            roundedCorners: getElementsValue(el, "rounded_corners"),
            label: getElementsValue(el, "link"),
            to: link.linkUrl,
          }
        })
      },
    },
  }
</script>
