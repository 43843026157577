<template>
  <Container space-before space-after>
    <Cards :layout="layout">
      <AlchemyCard
        v-for="card in nestedElements"
        :key="card.id"
        :element="card"
        :image-size="imageSize"
        :layout="layout"
        :preserve="preserve"
      />
    </Cards>
  </Container>
</template>

<script>
  import AlchemyCard from "./card"
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    components: { AlchemyCard },
    mixins: [AlchemyElement],
    computed: {
      imageSize() {
        const value = parseInt(this.getValue("image_size"), 10)
        if (isNaN(value)) {
          return null
        }
        return value
      },
      nestedElements() {
        return this.element.nestedElements
      },
      layout() {
        return this.getValue("layout")
      },
      preserve() {
        return this.getValue("preserve_image_content")
      },
    },
  }
</script>
